import { ENDPOINT_WITH_FILTERS, FilterParamsCommonDto } from '@appTypes/models/common.dto';
import {
  UpdateUserDto,
  UserDto,
  UserRole,
  UsersDto,
  UsersResponse,
} from '@appTypes/models/user.dto';
import { store } from '@redux/store';
import { createFullName } from '@utils/data/createFullName';
import { createEndpointQueryForAllItems, createParams } from '@utils/utils';
import { coreApi } from './core';

export type ProfilesData = {
  id: number;
  email: string;
  fullname: string;
  name: string;
  role: UserRole;
}[];

type UsersDataForSelect = {
  id: number;
  name: string;
}[];

export const usersApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<UserDto, number>({
      query: (id) => `users/${id}`,
      providesTags: (result, error, id) => [{ type: 'User', id }],
    }),
    getUsers: builder.query<UsersDto, void>({
      query: () => `users`,
    }),
    getUsersFiltered: builder.query<UsersResponse, FilterParamsCommonDto>({
      query: ({ ...params }) => `users?${createParams(params)}`,
      providesTags: ['Users'],
    }),
    getUsersForSelect: builder.query<UsersDataForSelect, void>({
      query: () =>
        `${createEndpointQueryForAllItems(ENDPOINT_WITH_FILTERS.USERS)}&order_by=last_name`,
      transformResponse: (response: UsersDto) => {
        if (!response.users) return [];

        const users = response.users.map(({ first_name, last_name, id, email }) => ({
          id,
          name: `${createFullName(first_name, last_name)}| ${email}`,
        }));

        return users;
      },
    }),
    getProfiles: builder.query<ProfilesData, void>({
      query: () =>
        `${createEndpointQueryForAllItems(ENDPOINT_WITH_FILTERS.USERS)}&order_by=last_name`,
      transformResponse: (response: UsersDto) => {
        const { currentUser } = store.getState().authData;

        const profiles = [...response.users]
          .filter((value) => value.id !== currentUser?.id)
          .map(({ first_name, last_name, email, role, id }) => ({
            id,
            name: [createFullName(first_name, last_name), email].join(''), // field name is used by SelectBox for keys and for searching, it has to be unique
            fullname: createFullName(first_name, last_name),
            email,
            role: role.name,
          }));

        return profiles;
      },
    }),
    updateUser: builder.mutation<string, UpdateUserDto>({
      query: ({ id, ...data }) => ({
        url: `users/${id}`,
        method: 'PATCH',
        data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'User', id }, 'Users', 'Sites', 'Site'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserQuery,
  useGetProfilesQuery,
  useGetUsersFilteredQuery,
  useGetUsersForSelectQuery,
  useUpdateUserMutation,
} = usersApi;
