import { ButtonDetail } from '@components/Buttons/Buttons';
import { EllipsisTypography } from '@components/EllipsisTypography';
import { Box } from '@mui/material';

export const DetailCell = ({
  children,
  onDetailClick,
  detailBtnText,
  color,
}: React.PropsWithChildren<{
  onDetailClick(): void;
  detailBtnText?: string;
  color?: string;
}>) => (
  <Box
    sx={{
      width: '100%',
      display: 'grid',
      alignItems: 'center',
      gridTemplateColumns: 'auto 100px',
      gridGap: '15px',
    }}
  >
    <EllipsisTypography maxWidth="100%" variant="subtitle2" color={color}>
      {children as string}
    </EllipsisTypography>

    <ButtonDetail onClick={onDetailClick}>{detailBtnText}</ButtonDetail>
  </Box>
);
