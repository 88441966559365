import { EcdDevicesPermissions } from '@appTypes/models/site.dto';
import { dictionary } from '@settings/dictionary';

export const EcdPermissionTitles = {
  [EcdDevicesPermissions.VIEW]: dictionary.ecdPermissionView,
  [EcdDevicesPermissions.BASIC_EDIT]: dictionary.ecdPermissionBasicEdit,
  [EcdDevicesPermissions.ADVANCED_EDIT]: dictionary.ecdPermissionAdvancedEdit,
} as const;

export const getEcdPermissionTitle = (ecdPermission?: Nullable<EcdDevicesPermissions>): string =>
  EcdPermissionTitles[ecdPermission ?? EcdDevicesPermissions.VIEW];
