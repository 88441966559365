import { UserDto } from '@appTypes/models/user.dto';
import ConfirmModal from '@components/Modals/ConfirmModal';
import { Typography } from '@mui/material';

interface ToggleUserActivationModalProps {
  user: UserDto | undefined;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: () => void;
}

export const ToggleUserActivationModal = ({
  user,
  open,
  setOpen,
  onConfirm,
}: ToggleUserActivationModalProps) => {
  const handleFormSubmit = () => {
    onConfirm();
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  if (!user) {
    return null;
  }

  const actionText = user.is_active ? 'deactivate' : 'activate';
  const dialogTitle = `${actionText.charAt(0).toUpperCase() + actionText.slice(1)} user`;

  return (
    <ConfirmModal
      open={open}
      setOpen={setOpen}
      dialogTitle={dialogTitle}
      confirmButtonCaption={user.is_active ? 'DEACTIVATE' : 'ACTIVATE'}
      onConfirm={handleFormSubmit}
      onCancel={handleCancel}
    >
      <Typography variant="body1">
        Are you sure you want to {actionText} {user.last_name} {user.first_name}? They will{' '}
        {user.is_active ? 'no longer have' : 'regain'} access to the platform.
      </Typography>
    </ConfirmModal>
  );
};
