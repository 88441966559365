import React, { ReactNode } from 'react';
import { usePalette } from '@hooks';
import { Tooltip as MuiTooltip, TooltipProps } from '@mui/material';

type CustomTooltipProps = Omit<TooltipProps, 'title' | 'children'> & {
  children: ReactNode;
  trigger: React.ReactElement;
};

export const Tooltip = ({ trigger, children, ...props }: CustomTooltipProps) => {
  const currentPalette = usePalette();
  const tooltipColor = currentPalette.primary.main;

  return (
    <MuiTooltip
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: tooltipColor,
            color: currentPalette.getContrastText(tooltipColor),
            padding: '8px',
            fontSize: '14px',
            lineHeight: '20px',
            borderRadius: 0,
            '& .MuiTooltip-arrow': {
              color: tooltipColor,
            },
          },
        },
      }}
      {...props}
      title={children}
    >
      <span>{trigger}</span>
    </MuiTooltip>
  );
};
